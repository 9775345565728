import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";

import { statesArray } from "../../assets/constants/stateCounty";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
  Checkbox,
  Popconfirm,
} from "antd";
import {
  END_POINT,
  optionsArray,
  refferEditFormItems,
} from "../../assets/constants/global";
import {
  deleteUser,
  editUserAccount,
  remappedRefferData,
  resumeUser,
  suspendUser,
} from "../../services/helpers";

function Tier1EditScreen() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);
  const [findCounties, setFindCounties] = useState();
  const [citiesObject, setCitiesObject] = useState();
  const [statesData, setStatesData] = useState(["new"]);
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const API_URL = `${END_POINT}/v1/admin/tier-1/update/${id}`;
  const [primaryLanguage, setPrimaryLanguage] = useState([]);
  const [primaryAreaArray, setPrimaryAreaArray] = useState([]);
  const [secondaryLanguageVisible, setSecondaryLanguageVisible] = useState(
    !!data?.secondaryLanguage
  );

  const selectOption = [
    { label: "Arabic", value: "Arabic" },
    { label: "Bengali", value: "Bengali" },
    { label: "Cantonese", value: "Cantonese" },
    { label: "Farsi", value: "Farsi" },
    { label: "English", value: "English" },
    { label: "French", value: "French" },
    { label: "German", value: "German" },
    { label: "Greek", value: "Greek" },
    { label: "Hebrew", value: "Hebrew" },
    { label: "Hindi", value: "Hindi" },
    { label: "Hungarian", value: "Hungarian" },
    { label: "Italian", value: "Italian" },
    { label: "Japanese", value: "Japanese" },
    { label: "Korean", value: "Korean" },
    { label: "Mandarin", value: "Mandarin" },
    { label: "Polish", value: "Polish" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Russian", value: "Russian" },
    { label: "Spanish", value: "Spanish" },
    { label: "Tagalog", value: "Tagalog" },
    { label: "Thai", value: "Thai" },
    { label: "Turkish", value: "Turkish" },
    { label: "Vietnamese", value: "Vietnamese" },
  ];

  const onFinish = async (values) => {
    try {
      setIsLoading(true);

      const { quote, primaryLanguage, secondaryLanguage, ...restValues } =
        values;

      const statesInfo = statesData?.map((item, index) => ({
        id: index + 1,
        stateName: values[`state${index + 1}`],
        licenseNumber: values[`licenseNumber${index + 1}`],
      }));

      const primaryAreaInfo = {
        stateName: values?.primaryArea,
        countyName: values?.county,
        cities: checkBoxValues,
      };

      await editUserAccount(
        { ...restValues, qoute: quote, primaryLanguage, secondaryLanguage },
        API_URL,
        statesInfo,
        primaryAreaInfo
      );
      setIsLoading(false);
      navigate("/tier1");
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ primaryLanguage });
  }, [primaryLanguage]);

  const handleAdditional = () => {
    setStatesData((prev) => [...prev, "new"]);
  };

  const handleCheckBoxValue = (item) => {
    if (!checkBoxValues?.includes(item)) {
      setCheckBoxValues((prev) => {
        if (prev) {
          return [...prev, item]; // Returning the updated array when prev is truthy
        } else {
          return [item]; // Returning a new array with the single item when prev is falsy
        }
      });
    } else {
      setCheckBoxValues(checkBoxValues?.filter((v) => v !== item));
    }
  };

  const onChange = (value, index) => {
    const newObject = statesArray?.find((el) => el.stateName === value);
    newObject.id = index;
    setPrimaryAreaArray((prev) => {
      const existingIndex = prev.findIndex((obj) => obj.id === index);
      if (existingIndex !== -1) {
        prev.splice(existingIndex, 1);
      }
      return [...prev, newObject];
    });
  };

  useEffect(() => {
  }, [primaryLanguage]);

  useEffect(() => {
    form.setFieldsValue(remappedRefferData(data));
    form.setFieldsValue({
      primaryArea: data?.primaryAreaInfo?.stateName,
      primaryLanguage: data?.primaryLanguage || undefined,
      secondaryLanguage: data?.secondaryLanguage || undefined,
      otp: data?.otp || undefined,
    });

    setPrimaryLanguage(data?.primaryLanguage || undefined);

    if (data?.primaryAreaInfo?.cities) {
      setCheckBoxValues(data.primaryAreaInfo.cities);
    }

    const initialPrimaryArea = data?.primaryAreaInfo?.stateName;
    if (initialPrimaryArea) {
      const primaryAreaState = statesArray.find(
        (state) => state.stateName === data?.primaryAreaInfo?.stateName
      );

      if (primaryAreaState) {
        setPrimaryAreaArray([primaryAreaState]);
        setFindCounties(primaryAreaState);
        form.setFieldsValue({ primaryArea: initialPrimaryArea });
      }
    }
    setSecondaryLanguageVisible(!!data?.secondaryLanguage); // Set visibility based on the response
  }, [data]);

  useEffect(() => {
    if (primaryAreaArray.length > 0) {
      form.setFieldsValue({
        primaryArea: primaryAreaArray[0].stateName,
      });
    }
  }, [primaryAreaArray]);

  const confirmDelete = async (tier, id) => {
    try {
      await deleteUser(tier, id);
      navigate("/tier1");
    } catch (error) {
    }
  };
  const confirmSuspendUser = async (tier, id) => {
    try {
      await suspendUser(tier, id);
      navigate("/tier1");
    } catch (error) {
    }
  };
  const confirmResumeUser = async (tier, id) => {
    try {
      await resumeUser(tier, id);
      navigate("/tier1");
    } catch (error) {
    }
  };

  const cancel = (e) => {
  };
  const statusArray = [
    {
      id: 1,
      label: "Approved",
      value: "Approved",
    },
    {
      id: 2,
      label: "Rejected",
      value: "Rejected",
    },
  ];

  return (
    <div className="main_container_div_form_edit">
      <div className="main_inner_div_form">
        <Row
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          <Typography className="heading_text_form">
            MemberID: {data?.memberID}
          </Typography>
          <Col>
            <Popconfirm
              title="Delete the user"
              description="Are you sure to delete this user?"
              onConfirm={() => confirmDelete("tier-1", data?._id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" style={{ marginRight: "8px" }}>
                Delete User
              </Button>
            </Popconfirm>
            <Popconfirm
              title={data?.isSuspended ? "Resume the user" : "Suspend the user"}
              description={
                data?.isSuspended
                  ? "Are you sure to resume this user?"
                  : "Are you sure to suspend this user?"
              }
              onConfirm={
                data?.isSuspended
                  ? () => confirmResumeUser("tier-1", data?._id)
                  : () => confirmSuspendUser("tier-1", data?._id)
              }
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" style={{ marginRight: "8px" }}>
                {data?.isSuspended ? "Resume User" : "Suspend User"}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <div className="form_1st">
          <Typography
            className="heading_text_form"
            style={{ textAlign: "center" }}
          >
            Edit Recruiter Profile
          </Typography>
        </div>

        <Box style={{ marginTop: "20px" }}>
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: false,
                      message: "Please select your primary language",
                    },
                  ]}
                >
                  <Select
                    dropdownClassName="custom-select-dropdown"
                    showSearch
                    allowClear={false}
                    options={statusArray?.map((el) => ({
                      label: el?.label,
                      value: el?.value,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              {refferEditFormItems?.map((item, index) => (
                <Col xs={24} sm={12} key={index}>
                  <Form.Item
                    label={
                      <Typography style={{ color: "black" }}>
                        {item?.label}
                      </Typography>
                    }
                    name={item?.name}
                    rules={[
                      {
                        required: item?.required,
                        message: item?.message,
                      },
                      ...(item?.extraRules || []),
                      ...(item?.name === "phone"
                        ? [
                            {
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.resolve();
                                }
                                if (value.includes("+")) {
                                  return Promise.reject(
                                    "Please input your phone number without +"
                                  );
                                }
                                if (value.length !== 10) {
                                  return Promise.reject(
                                    "Please complete 10 characters"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]
                        : []),
                    ]}
                  >
                    {item?.field}
                  </Form.Item>
                </Col>
              ))}
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item
                  label="Primary Language"
                  name="primaryLanguage"
                  rules={[
                    {
                      required: true,
                      message: "Please select your primary language",
                    },
                  ]}
                >
                  <Select
                    dropdownClassName="custom-select-dropdown"
                    showSearch
                    allowClear
                    onChange={(value) => {
                      setPrimaryLanguage(value);
                      form.setFieldsValue({ primaryLanguage: value });
                    }}
                    options={selectOption}
                    value={primaryLanguage}
                  />
                </Form.Item>
              </Col>
              {secondaryLanguageVisible ? (
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <Typography style={{ color: "black" }}>
                        Secondary Language
                      </Typography>
                    }
                    name="secondaryLanguage"
                    rules={[
                      {
                        required: false,
                        message: "Please select your secondary language",
                      },
                    ]}
                  >
                    <Select
                      dropdownClassName="custom-select-dropdown"
                      showSearch
                      allowClear
                      options={selectOption}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ marginTop: "32px" }}
                      onClick={() => setSecondaryLanguageVisible(true)}
                    >
                      Add Secondary Language
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>

            {statesData?.map((item, index) => {
              return (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="License Number"
                        name={`licenseNumber${index + 1}`}
                        rules={[
                          {
                            required: true,
                            message: "Please select your primary language",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="State"
                        name={`state${index + 1}`}
                        rules={[
                          {
                            required: true,
                            message: "Please add a state",
                          },
                        ]}
                      >
                        <Select
                          dropdownClassName="custom-select-dropdown"
                          showSearch
                          allowClear
                          options={optionsArray}
                          onChange={(value) => onChange(value, index)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            })}

            <Box className="addition_div">
              <Typography
                className="additional_text"
                onClick={() => handleAdditional()}
              >
                Add Additional License
              </Typography>
            </Box>
            <Divider />

            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <Typography style={{ color: "black" }}>
                        Primary Area
                      </Typography>
                    }
                    name="primaryArea"
                    rules={[
                      {
                        required: true,
                        message: "Please select a primary Area",
                      },
                    ]}
                  >
                    <Select
                      dropdownClassName="custom-select-dropdown"
                      showSearch
                      allowClear
                      options={primaryAreaArray?.map((el) => ({
                        label: el?.stateName,
                        value: el?.stateName,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  shouldUpdate={(prev, curr) =>
                    prev.primaryArea !== curr.primaryArea
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    <>
                      {setFindCounties(
                        statesArray?.find(
                          (item) =>
                            item.stateName === getFieldValue("primaryArea")
                        )
                      )}
                    </>;
                  }}
                </Form.Item>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <Typography style={{ color: "black" }}>
                        Counties
                      </Typography>
                    }
                    name="county"
                    rules={[
                      {
                        required: true,
                        message: "Please select a county",
                      },
                    ]}
                  >
                    <Select
                      dropdownClassName="custom-select-dropdown"
                      showSearch
                      allowClear
                      options={findCounties?.county?.map((el) => ({
                        label: el?.countyName,
                        value: el?.countyName,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={(prev, curr) => prev.county !== curr.county}
                    noStyle
                  >
                    {({ getFieldValue }) => (
                      <>
                        {setCitiesObject(
                          findCounties?.county?.find(
                            (el) => el?.countyName === getFieldValue("county")
                          )
                        )}
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
            {/* )} */}

            {citiesObject && (
              <>
                <Typography>Check Cities</Typography>
                <Form.Item name="all" valuePropName="checked">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={
                        citiesObject?.cities?.length === checkBoxValues?.length
                      }
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCheckBoxValues(
                            citiesObject?.cities?.map((v) => v)
                          );
                        } else {
                          setCheckBoxValues([]);
                        }
                      }}
                    />
                    <p>{"All"}</p>
                  </div>
                </Form.Item>

                <Row gutter={[16, 16]}>
                  {citiesObject?.cities?.map((item, index) => (
                    <>
                      <Col xs={12} sm={8} lg={6}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={checkBoxValues?.includes(item)}
                            onChange={() => handleCheckBoxValue(item)}
                          />
                          <p>{item}</p>
                        </div>
                      </Col>
                    </>
                  ))}
                </Row>
              </>
            )}

            <Form.Item>
              <Box
                sx={{ width: "100%", textAlign: "center", marginTop: "20px" }}
              >
                <Button
                  className="text-hide"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <Spin /> : "SUBMIT"}
                </Button>
              </Box>
            </Form.Item>
          </Form>
        </Box>
      </div>
    </div>
  );
}

export default Tier1EditScreen;
