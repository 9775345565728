import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";

import { statesArray } from "../../assets/constants/stateCounty";

import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Spin,
  Typography,
  Select,
  Popconfirm,
} from "antd";
import {
  deleteUser,
  editInterviewerAccount,
  remappedInterviewerData,
  resumeUser,
  suspendUser,
} from "../../services/helpers";
import {
  END_POINT,
  interviewerEditFormItems,
} from "../../assets/constants/global";

const Tier2EditScreen = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [primaryLanguage, setPrimaryLanguage] = useState([]);

  const selectOption = [
    { label: "Arabic", value: "Arabic" },
    { label: "Bengali", value: "Bengali" },
    { label: "Cantonese", value: "Cantonese" },
    { label: "Farsi", value: "Farsi" },
    { label: "English", value: "English" },
    { label: "French", value: "French" },
    { label: "German", value: "German" },
    { label: "Greek", value: "Greek" },
    { label: "Hebrew", value: "Hebrew" },
    { label: "Hindi", value: "Hindi" },
    { label: "Hungarian", value: "Hungarian" },
    { label: "Italian", value: "Italian" },
    { label: "Japanese", value: "Japanese" },
    { label: "Korean", value: "Korean" },
    { label: "Mandarin", value: "Mandarin" },
    { label: "Polish", value: "Polish" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Russian", value: "Russian" },
    { label: "Spanish", value: "Spanish" },
    { label: "Tagalog", value: "Tagalog" },
    { label: "Thai", value: "Thai" },
    { label: "Turkish", value: "Turkish" },
    { label: "Vietnamese", value: "Vietnamese" },
  ];

  const onFinish1 = async (values) => {
    setIsLoading(true);
    try {
      const API_URL = `${END_POINT}/v1/admin/tier-2/update/${id}`;
      const { primaryLanguage, secondaryLanguage } = values;

      await editInterviewerAccount(
        values,
        checkBoxValues,
        API_URL,
        primaryLanguage,
        secondaryLanguage
      );
      setIsLoading(false);
      navigate("/tier2");
    } catch (error) {
      form.resetFields();
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const handleCheckBoxValue = (item) => {
    setCheckBoxValues((prev) =>
      prev.includes(item) ? prev.filter((v) => v !== item) : [...prev, item]
    );
  };

  const setValuesInForm = () => {
    const remappedData = remappedInterviewerData(data);
    form?.setFieldsValue({
      ...remappedData,
      primaryLanguage: data?.primaryLanguage || undefined, // Remove default value handling
      secondaryLanguage: data?.secondaryLanguage || undefined,
      otp: data?.otp || undefined,
    });
    setPrimaryLanguage(data?.primaryLanguage || undefined); // Ensure the state reflects the API data

    setCheckBoxValues(
      Array.isArray(data?.licenseStates) ? data?.licenseStates : []
    );
    setSecondaryLanguageVisible(!!data?.secondaryLanguage); // Set visibility based on the response
  };

  useEffect(() => {}, [primaryLanguage]);

  useEffect(() => {
    setValuesInForm();
  }, [data]);

  const confirmDelete = async (tier, id) => {
    try {
      await deleteUser(tier, id);
      navigate("/tier2");
    } catch (error) {}
  };

  const confirmSuspendUser = async (tier, id) => {
    try {
      await suspendUser(tier, id);
      navigate("/tier2");
    } catch (error) {}
  };

  const confirmResumeUser = async (tier, id) => {
    try {
      await resumeUser(tier, id);
      navigate("/tier2");
    } catch (error) {}
  };

  const cancel = (e) => {};

  const [secondaryLanguageVisible, setSecondaryLanguageVisible] = useState(
    !!data?.secondaryLanguage
  );

  const statusArray = [
    {
      id: 1,
      label: "Approved",
      value: "Approved",
    },
    {
      id: 2,
      label: "Rejected",
      value: "Rejected",
    },
  ];
  return (
    <div className="main_container_div_form_edit">
      <div className="main_inner_div_form">
        <Row
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="heading_text_form">
            MemberID: {data?.memberID}
          </Typography>
          <Col>
            <Popconfirm
              title="Delete the user"
              description="Are you sure to delete this user?"
              onConfirm={() => confirmDelete("tier-2", data?._id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" style={{ marginRight: "8px" }}>
                Delete User
              </Button>
            </Popconfirm>
            <Popconfirm
              title={data?.isSuspended ? "Resume the user" : "Suspend the user"}
              description={
                data?.isSuspended
                  ? "Are you sure to resume this user?"
                  : "Are you sure to suspend this user?"
              }
              onConfirm={
                data?.isSuspended
                  ? () => confirmResumeUser("tier-2", data?._id)
                  : () => confirmSuspendUser("tier-2", data?._id)
              }
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" style={{ marginRight: "8px" }}>
                {data?.isSuspended ? "Resume User" : "Suspend User"}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <div className="form_1st">
          <Typography
            className="heading_text_form"
            style={{ textAlign: "center" }}
          >
            Edit Interviewer Profile
          </Typography>
        </div>
        <Box style={{ marginTop: "20px" }}>
          <Form
            name="Interviewer"
            onFinish={onFinish1}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: false,
                      message: "Please select your primary language",
                    },
                  ]}
                >
                  <Select
                    dropdownClassName="custom-select-dropdown"
                    showSearch
                    allowClear={false}
                    options={statusArray?.map((el) => ({
                      label: el?.label,
                      value: el?.value,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              {interviewerEditFormItems?.map((item, index) => (
                <Col xs={24} sm={12} lg={12} key={index}>
                  <Form.Item
                    label={item?.label}
                    name={item?.name}
                    rules={[
                      {
                        required: item?.required,
                        message: item?.message,
                      },
                      ...(item?.name === "phone"
                        ? [
                            {
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.resolve();
                                }
                                if (value.includes("+")) {
                                  return Promise.reject(
                                    "Please input your phone number without +"
                                  );
                                }
                                if (value.length !== 10) {
                                  return Promise.reject(
                                    "Please complete 10 characters"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]
                        : []),
                    ]}
                  >
                    {item?.field}
                  </Form.Item>
                </Col>
              ))}
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item
                  label="Primary Language"
                  name="primaryLanguage"
                  rules={[
                    {
                      required: true,
                      message: "Please select your primary language",
                    },
                  ]}
                >
                  <Select
                    dropdownClassName="custom-select-dropdown"
                    showSearch
                    allowClear
                    onChange={(value) => {
                      setPrimaryLanguage(value);
                      form.setFieldsValue({ primaryLanguage: value });
                    }}
                    options={selectOption}
                    value={primaryLanguage}
                  />
                </Form.Item>
              </Col>
              {secondaryLanguageVisible ? (
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <Typography style={{ color: "black" }}>
                        Secondary Language
                      </Typography>
                    }
                    name="secondaryLanguage"
                    rules={[
                      {
                        required: false,
                        message: "Please select your secondary language",
                      },
                    ]}
                  >
                    <Select
                      dropdownClassName="custom-select-dropdown"
                      showSearch
                      allowClear
                      options={selectOption}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ marginTop: "32px" }}
                      onClick={() => setSecondaryLanguageVisible(true)}
                    >
                      Add Secondary Language
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Typography className="heading_text" style={{ color: "black" }}>
              Additional License states
            </Typography>
            <Form.Item name="all" valuePropName="checked">
              <div style={{ display: "flex", gap: "10px" }}>
                <Checkbox
                  checked={statesArray?.length === checkBoxValues?.length}
                  onChange={(event) => {
                    if (event.target.checked === true) {
                      setCheckBoxValues(statesArray?.map((v) => v.stateName));
                    } else {
                      setCheckBoxValues([]);
                    }
                  }}
                />
                <p>{"All"}</p>
              </div>
            </Form.Item>

            <Row gutter={[16, 16]}>
              {statesArray?.map((item, index) => (
                <Col xs={12} md={8} lg={6} key={index}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Checkbox
                      checked={checkBoxValues?.includes(item?.stateName)}
                      onChange={() => handleCheckBoxValue(item?.stateName)}
                    />
                    <p>{item?.stateName}</p>
                  </div>
                </Col>
              ))}
            </Row>

            <Form.Item>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "25px",
                }}
              >
                <Button
                  className="text-hide"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <Spin /> : "SUBMIT"}
                </Button>
              </Box>
            </Form.Item>
          </Form>
        </Box>
      </div>
    </div>
  );
};

export default Tier2EditScreen;
