import React from "react";

import { Button, Typography } from "antd";
import { NavLink } from "react-router-dom";

export default function GlobalHeader2({ textHeadline }) {
  return (
    <div className="inner_div_2 main-header ">
      <Typography className="heading_text">{textHeadline}</Typography>
      <NavLink to="/tier-adminForm">
        <Button className="text-hide">Add+</Button>
      </NavLink>
    </div>
  );
}
