import React, { useState, useEffect } from "react";

import axios from "axios";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button, Spin, Typography, message } from "antd";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";

import i18n from "../../i18n";
import Loading from "../loading/Loading";
import { images } from "../../assets/images";
import { END_POINT } from "../../assets/constants/global";
import AlreadyApproved from "../../screens/webForm/alreadyApproved";
import { setSelectedLanguage } from "../../redux/reduxSlice/dataSlice";

export default function ApprovalScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const { selectedLanguage } = useSelector((state) => state.languages);

  const languagesEnum = [
    { language: "English", locale: "en" },
    { language: "Arabic", locale: "ar" },
    { language: "Bengali", locale: "bn" },
    { language: "Cantonese", locale: "yue" },
    { language: "Farsi", locale: "fa" },
    { language: "French", locale: "fr" },
    { language: "German", locale: "de" },
    { language: "Greek", locale: "el" },
    { language: "Hebrew", locale: "he" },
    { language: "Hindi", locale: "hi" },
    { language: "Hungarian", locale: "hu" },
    { language: "Italian", locale: "it" },
    { language: "Japanese", locale: "ja" },
    { language: "Korean", locale: "ko" },
    { language: "Mandarin", locale: "zh" },
    { language: "Polish", locale: "pl" },
    { language: "Portuguese", locale: "pt" },
    { language: "Russian", locale: "ru" },
    { language: "Spanish", locale: "es" },
    { language: "Tagalog", locale: "tl" },
    { language: "Thai", locale: "th" },
    { language: "Turkish", locale: "tr" },
    { language: "Vietnamese", locale: "vi" },
  ];

  useEffect(() => {
    if (data && data.application && data.application.primaryLanguage) {
      const userLanguage =
        languagesEnum.find(
          (lng) => lng.language === data.application.primaryLanguage
        )?.locale || "en";
      dispatch(setSelectedLanguage(userLanguage));
    }
  }, [data]);

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  const statusUpdatedApproved = async () => {
    const updatedStatus = "Approved";
    setIsStatusLoading(true);
    try {
      const response = await axios.post(
        `${END_POINT}/v1/applications/update/status/${id}`,
        {
          status: updatedStatus,
        }
      );
      setIsStatusLoading(false);
      message.success(response?.data?.message);
      navigate(`/accepted/${id}`, {
        state: data,
      });
    } catch (error) {
      setIsStatusLoading(false);
      message?.error(
        error?.response?.data?.message || "We are working to fix the problems"
      );
    }
  };
  if (!data) return <Loading />;
  return (
    <>
      {data.application ? (
        <>
          {data?.application?.status === "Approved" ? (
            <>
              <AlreadyApproved
                status={data?.application?.status}
                profileImage={data?.referee?.profileImage}
              />
            </>
          ) : (
            <>
              <div className="main-form">
                <div className="main_container_div">
                  <div className="main_inner_div">
                    <div className="form_inner">
                      <Box className="card_inner">
                        <div className="logoSet">
                          <Avatar
                            src={images?.privont_logo}
                            size={120}
                            style={{
                              backgroundColor: "black",
                              padding: "10px",
                            }}
                          />
                        </div>

                        <Typography
                          className="normal_text"
                          style={{ textAlign: "center" }}
                        >
                          {t(
                            "common.Thank You for expressing interest in joining our exclusive members-only organization. specialized members will be reaching out to approve your application"
                          )}
                        </Typography>
                        <Typography
                          className="normal_text"
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            color: "white",
                          }}
                        >
                          {t(
                            "common.By accepting you agree to have 3 specialized members who will be reaching out to approve your application"
                          )}
                        </Typography>
                        <Box>
                          <Button
                            style={{
                              borderRadius: "8px",
                              boxShadow: "black 6px 5px 11px",
                            }}
                            type="primary"
                            className="btn"
                            onClick={() => statusUpdatedApproved()}
                            disabled={isStatusLoading}
                          >
                            {isStatusLoading ? <Spin /> : t("common.ACCEPT")}
                          </Button>

                          <Button
                            style={{
                              borderRadius: "8px",
                              boxShadow: "black 6px 5px 11px",
                            }}
                            type="primary"
                            className="btn"
                            onClick={() =>
                              navigate(`/declined/${id}`, {
                                state: data,
                              })
                            }
                          >
                            {t("common.DECLINE")}
                          </Button>
                        </Box>
                        <Box>
                          <div className="logoSet">
                            <Avatar
                              src={images?.privont_logo}
                              size={70}
                              style={{
                                backgroundColor: "black",
                                padding: "10px",
                              }}
                            />
                            <Typography className="heading_text">
                              PRIVONT
                            </Typography>
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          Error
          <Typography>{data}</Typography>
        </>
      )}
    </>
  );
}
