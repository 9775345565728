import Public from "./Public";
import Protected from "./Protected";
import AppLayout from "../layout/Layout";
import LazyComponent from "./LazyComponent";
import Tier1Member from "../screens/tier1/tier1Member";
import Tier2Member from "../screens/tier2/tier2Member";
import Tier3Member from "../screens/tier3/tier3Member";
import tier4Member from "../screens/tier4/tier4Member";
import { loader as bugsLoader } from "../screens/bugs/bugs";
import { loader as Tier1Loader } from "../screens/tier1/tier1";
import { loader as Tier2Loader } from "../screens/tier2/tier2";
import { loader as Tier3Loader } from "../screens/tier3/tier3";
import { loader as editTier1Loader } from "../screens/tier1/tier1Edit";
import { loader as editTier2Loader } from "../screens/tier2/tier2Edit";
import { loader as approvalLoader } from "../screens/approval/approval";
import { loader as billingLoader } from "../screens/billing/billingInfo";
import { loader as dashboardLoader } from "../screens/dashboard/Dashboard";
import { loader as businessCardLoader } from "../screens/business/business";
import { loader as editBusinessLoader } from "../screens/tier1/editBusiness";
import { loader as suggestionLoader } from "../screens/suggestion/suggestion";
import { loader as categoriesLoader } from "../screens/categories/categories";
import { loader as editBusinessLoaderTier2 } from "../screens/tier2/tier2BusinessCard";

export const SiteMap = {
  home: {
    title: "home",
    path: "/",
    element: (
      <Public>
        <LazyComponent path="login/login" />
      </Public>
    ),
    description: "home-Page",
  },
  dashboard: {
    title: "dashboard",
    path: "/dashboard",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="dashboard/Dashboard" />
        </AppLayout>
      </Protected>
    ),
    loader: dashboardLoader,
  },

  subAdmin: {
    title: "subAdmin",
    path: "/subAdmin",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="subAdmin/subAdmin" />
        </AppLayout>
      </Protected>
    ),
  },
  tier1: {
    title: "tier1",
    path: "/tier1",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier1/tier1" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier1Loader,
    description: "tier1",
  },

  tier1Member: {
    title: "tier1Member",
    path: "/tier1/info/detail1/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1Member" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier1Member,
    description: "tier1Member",
  },
  Tier1DetailsT1: {
    title: "tier1DetailsT1",
    path: "/tier1/info/detailTier1/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT1" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT1",
  },
  Tier1DetailsT2: {
    title: "tier1DetailsT2",
    path: "/tier1/info/detailTier2/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT2" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT2",
  },
  Tier1DetailsT3: {
    title: "tier1DetailsT3",
    path: "/tier1/info/detailTier3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT3" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT3",
  },
  Tier1DetailsT4: {
    title: "tier1DetailsT4",
    path: "/tier1/info/detailTier4/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT4" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT4",
  },
  tier1Edit: {
    title: "tier1Edit",
    path: "/tier1/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1Edit" />
        </AppLayout>
      </Protected>
    ),
    loader: editTier1Loader,
    description: "tier1",
  },
  tier2: {
    title: "tier2",
    path: "/tier2",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier2/tier2" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2",
    loader: Tier2Loader,
  },
  tier2Member: {
    title: "tier2Member",
    path: "/tier2/info/detail2/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2Member" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier2Member,
    description: "tier2Member",
  },

  tier2Edit: {
    title: "tier2Edit",
    path: "/tier2/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2Edit" />
        </AppLayout>
      </Protected>
    ),
    loader: editTier2Loader,
    description: "tier2-details",
  },

  tier3: {
    title: "tier3",
    path: "/tier3",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier3/tier3" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier3Loader,
    description: "Tier-3-page",
  },
  tier3Member: {
    title: "tier3Member",
    path: "/tier3/info/detail3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier3/tier3Member" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier3Member,
    description: "tier3Member",
  },
  Tier2DetailsT3: {
    title: "tier2DetailsT3",
    path: "/tier2/info/detailTier3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2DetailsT3" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2DetailsT3",
  },
  Tier2DetailsT4: {
    title: "Tier2DetailsT4",
    path: "/tier2/info/detailTier4/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2DetailsT4" />
        </AppLayout>
      </Protected>
    ),
    description: "Tier2DetailsT4",
  },
  tier3Edit: {
    title: "tier3Edit",
    path: "/tier3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier3/tier3Edit" />
        </AppLayout>
      </Protected>
    ),
    description: "Details",
  },
  tier4: {
    title: "tier4",
    path: "/tier4",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier4/tier4" />
        </AppLayout>
      </Protected>
    ),
    description: "Tier-4-page",
  },
  tier4Member: {
    title: "tier4Member",
    path: "/tier4/info/detail4/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier4/tier4Member" />
        </AppLayout>
      </Protected>
    ),
    loader: tier4Member,
    description: "tier4Member",
  },

  webForm: {
    title: "webForm",
    path: "/webForm/:id",
    element: <LazyComponent path="webForm/webForm" />,
    description: "web-form-details",
  },
  OtpVerify: {
    title: "otpVerify",
    path: "/otpVerify",
    element: <LazyComponent path="webForm/otpVerify" />,
    description: "otpVerify-details",
  },
  Acceptence: {
    title: "acceptence",
    path: "/acceptence/:type/:id",
    element: <LazyComponent path="webForm/acceptence" />,
    description: "acceptence-details",
  },
  firstForm: {
    title: "firstForm",
    path: "/firstForm/:id",
    element: <LazyComponent path="webForm/firstForm" />,
    description: "first-form-details",
  },
  approval: {
    title: "approval",
    path: "/approval/:id",
    element: <LazyComponent path="approval/approval" />,
    loader: approvalLoader,
    description: "web-form details",
  },
  accepted: {
    title: "accepted",
    path: "/accepted/:id",
    element: <LazyComponent path="webForm/accepted" />,
    description: "web-form details",
  },
  termOfService: {
    title: "termOfService",
    path: "/termOfService/:id",
    element: <LazyComponent path="webForm/termOfService" />,
    description: "termOfService details",
  },
  privacyPolicy: {
    title: "privacyPolicy",
    path: "/privacyPolicy/:id",
    element: <LazyComponent path="webForm/privacyPolicy" />,
    description: "privacyPolicy details",
  },
  declined: {
    title: "declined",
    path: "/declined/:id",
    element: <LazyComponent path="webForm/declined" />,
    loader: approvalLoader,
    description: "web-form details",
  },
  welcome: {
    title: "welcome",
    path: "/welcome/:id",
    element: <LazyComponent path="webForm/welcome" />,
    description: "web-form details",
  },
  refferForm: {
    title: "reffer-form",
    path: "/reffer-form/:id?",
    element: <LazyComponent path="forms/refferMembershipForm" />,
    description: "Reffer-form details",
  },
  tierForm: {
    title: "tier-form",
    path: "/tier-form",
    element: (
      <Protected>
        <LazyComponent path="forms/tierForm" />
      </Protected>
    ),
    description: "Tier-form details",
  },
  tierAdminForm: {
    title: "tier-AdminForm",
    path: "/tier-adminForm",
    element: (
      <Protected>
        <LazyComponent path="forms/tierAdminForm" />
      </Protected>
    ),
    description: "Tier-adminForm details",
  },
  tierFormSecond: {
    title: "tier-form-Second",
    path: "/tier-form_second",
    element: (
      <Protected>
        <LazyComponent path="forms/tierForm2" />
      </Protected>
    ),
    description: "Tier-form-second details",
  },
  interviewerForm: {
    title: "interviewer-form",
    path: "/interviewer-form/:id",
    element: <LazyComponent path="forms/interviewerMembershipForm" />,
    description: "Interviewer-form details",
  },
  editBusinessForm: {
    title: "Edit business form tier 1",
    path: "/edit-business-form/tier-1/:id",
    element: <LazyComponent path="tier1/editBusiness" />,
    loader: editBusinessLoader,
    description: "Edit business form tier 1",
  },
  editBusinessFormTier2: {
    title: "Edit business form tier 2",
    path: "/edit-business-form/tier-2/:id",
    element: <LazyComponent path="tier2/tier2BusinessCard" />,
    loader: editBusinessLoaderTier2,
    description: "Edit business form tier 2",
  },
  BusinessCardForm: {
    title: "Business-Details",
    path: "/business-details",
    element: <LazyComponent path="tier1/businessDetails" />,
    description: "Business-Details",
  },
  BusinessCard: {
    title: "Business-Card-Details",
    path: "/business-card-details/:id",
    element: <LazyComponent path="business/business" />,
    description: "Business-card-Details",
    loader: businessCardLoader,
  },
  ThankYou: {
    title: "Thank-You",
    path: "/success",
    element: <LazyComponent path="forms/thankYou" />,
    description: "Business-Details",
  },
  ThankYouPrivate: {
    title: "ThankYouPrivate",
    path: "/successForm",
    element: <LazyComponent path="forms/thankYouPrivate" />,
    description: "Business-Details",
  },
  VCardEdit: {
    title: "VCardEdit",
    path: "/vcard/edit/:id",
    element: <LazyComponent path="enBizCard/vCardEdit" />,
    description: "VCardEdit-Details",
  },
  VCard: {
    title: "VCard",
    path: "/vcard/:id",
    element: <LazyComponent path="enBizCard/vCard" />,
    description: "vCard-Details",
  },
  Directory: {
    title: "Directory",
    path: "/directory",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="directory/directory" />
        </AppLayout>
      </Protected>
    ),
    description: "Directory",
  },
  Categories: {
    title: "categories",
    path: "/categories",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="categories/categories" />
        </AppLayout>
      </Protected>
    ),
    loader: categoriesLoader,
    description: "categories",
  },
  Suggestions: {
    title: "Suggestions",
    path: "/suggestions",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="suggestion/suggestion" />
        </AppLayout>
      </Protected>
    ),
    loader: suggestionLoader,
    description: "suggestion",
  },
  Bugs: {
    title: "Bugs",
    path: "/bugs",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="bugs/bugs" />
        </AppLayout>
      </Protected>
    ),
    loader: bugsLoader,
    description: "bugs",
  },
  BillingInfo: {
    title: "Billing Info",
    path: "/billing-info",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="billing/billingInfo" />
        </AppLayout>
      </Protected>
    ),
    loader: billingLoader,
    description: "Billing-info",
  },
};
