import "react-phone-input-2/lib/style.css";
import { Input, Select } from "antd";
import { statesArray } from "./stateCounty";

const globalConstants = ["name", "email", "password", "etc."];
const numberOfItems = 1000;

const arrayOfItems = Array.from(
  { length: numberOfItems },
  (_, index) => index + 1
);
const END_POINT = process.env.REACT_APP_END_POINT;
const RESPONSE = {
  OK: "ok",
  CANCEL: "cancelled",
};
const usersData = [
  {
    key: 1,
    name: "Mike",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: 2,
    name: "John",
    age: 42,
    address: "10 Downing Street",
  },
];

const optionStates = statesArray?.map((el) => ({
  label: el?.stateName,
  value: el?.stateName,
}));

const optionsArray = [...optionStates];

const specialtyOptions = [
  { label: "None", value: "None" },
  { label: "DPA", value: "DPA" },
  { label: "VA", value: "VA" },
  { label: "Commercial", value: "Commercial" },
  { label: "Land", value: "Land" },
  { label: "Rehab", value: "Rehab" },
  { label: "Manufactured", value: "Manufactured" },
  { label: "Barndominium", value: "Barndominium" },
  { label: "Condotels", value: "Condotels" },
  { label: "Coops", value: "Coops" },
  { label: "Down Payment Assistance", value: "Down Payment Assistance" },
  { label: "Fix and Flip", value: "Fix and Flip" },
  { label: "Foreign National", value: "Foreign National" },
  { label: "Hard Money", value: "Hard Money" },
  { label: "Jumbo", value: "Jumbo" },
  { label: "Land loans", value: "Land loans" },
  { label: "Native American Loans", value: "Native American Loans" },
  { label: "Non-QM", value: "Non-QM" },
  { label: "Reverse", value: "Reverse" },
  { label: "USDA (Rural Home)", value: "USDA (Rural Home)" },
];

const ConstructionData = [
  { label: "DPA", value: "DPA" },
  { label: "VA", value: "VA" },
  { label: "Commercial", value: "Commercial" },
  { label: "Land", value: "Land" },
  { label: "Rehab", value: "Rehab" },
  { label: "Manufactured", value: "Manufactured" },
  { label: "Barndominium", value: "Barndominium" },
  { label: "Condotels", value: "Condotels" },
  { label: "Coops", value: "Coops" },
  { label: "Down Payment Assistance", value: "Down Payment Assistance" },
  { label: "Fix and Flip", value: "Fix and Flip" },
  { label: "Foreign National", value: "Foreign National" },
  { label: "Hard Money", value: "Hard Money" },
  { label: "Jumbo", value: "Jumbo" },
  { label: "Land loans", value: "Land loans" },
  { label: "Native American Loans", value: "Native American Loans" },
  { label: "Non-QM", value: "Non-QM" },
  { label: "Reverse", value: "Reverse" },
  { label: "USDA (Rural Home)", value: "USDA (Rural Home)" },
];
const primaryLanguage = [
  { label: "Arabic", value: "Arabic" },
  { label: "Bengali", value: "Bengali" },
  { label: "Cantonese", value: "Cantonese" },
  { label: "Farsi", value: "Farsi" },
  { label: "English", value: "English" },
  { label: "French", value: "French" },
  { label: "German", value: "German" },
  { label: "Greek", value: "Greek" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Hindi", value: "Hindi" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Italian", value: "Italian" },
  { label: "Japanese", value: "Japanese" },
  { label: "Korean", value: "Korean" },
  { label: "Mandarin", value: "Mandarin" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Russian", value: "Russian" },
  { label: "Spanish", value: "Spanish" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Thai", value: "Thai" },
  { label: "Turkish", value: "Turkish" },
  { label: "Vietnamese", value: "Vietnamese" },
];

const primaryInput = [
  {
    id: 1,
    label: "Primary Language",
    name: "primaryLanguage",
    required: true,
    message: "Please select your primary",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={primaryLanguage}
      />
    ),
  },
];

const tierEditForm = [
  {
    id: 1,
    label: "Primary Language",
    name: "primaryLanguage",
    required: true,
    message: "Please select your primary",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={primaryLanguage}
      />
    ),
  },
  {
    id: 2,
    label: "Secondary Language",
    name: "secondaryLanguage",
    required: true,
    message: "Please select your secondary",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={primaryLanguage}
      />
    ),
  },
];

const refferFormItems = [
  {
    id: 1,
    label: "First Name",
    required: true,
    name: "firstName",
    message: "Please input your first Name",
    field: <Input type="text" />,
  },
  {
    id: 2,
    label: "Last Name",
    name: "lastName",
    required: false,
    message: "Please input your first last Name",
    field: <Input type="text" />,
  },
  {
    id: 3,
    label: "User Name",
    name: "username",
    required: true,
    message: "Please input your first last Name",
    field: <Input type="text" />,
  },
  {
    id: 4,
    label: "Phone Number",
    name: "phone",
    required: true,
    message: "Please input your phone number",
    field: (
      <Input
        type="tel"
        placeholder="Phone Number"
        maxLength={10}
        onKeyPress={(e) => {
          if (
            !/[0-9]/.test(e.key) &&
            !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)
          ) {
            e.preventDefault();
          }
        }}
        inputMode="numeric"
      />
    ),
  },
  {
    id: 5,
    label: "Email",
    name: "email",
    required: true,
    message: "Please input your Email",
    field: (
      <Input
        type="email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        title="Please enter a valid email address"
        required
      />
    ),
  },
  {
    id: 6,
    label: "Password",
    name: "password",
    required: true,
    message: "Please input your password",
    field: <Input type="text" />,
  },
  {
    id: 7,
    label: "Company Name",
    name: "companyName",
    required: true,
    message: "Please input your Company Name",
    field: <Input type="text" />,
  },
  {
    id: 8,
    label: "Team Name",
    name: "teamName",
    required: false,
    message: "Please input your Team Name",
    field: <Input type="text" />,
  },
  {
    id: 9,
    label: "Primary Language",
    name: "primary",
    required: true,
    message: "Please select your primary",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={primaryLanguage}
      />
    ),
  },
];
const refferEditFormItems = [
  {
    id: 2,
    label: "First Name",
    required: true,
    name: "firstName",
    message: "Please input your first Name",
    field: <Input type="text" />,
  },
  {
    id: 3,
    label: "Last Name",
    name: "lastName",
    required: false,
    message: "Please input your  last Name",
    field: <Input type="text" />,
  },
  {
    id: 4,
    label: "User Name",
    name: "username",
    required: true,
    message: "Please input your  userName",
    field: <Input type="text" readOnly />,
  },
  {
    id: 5,
    label: "Phone Number",
    name: "phone",
    required: true,
    message: "Please input your phone number",
    field: (
      <Input
        type="tel"
        placeholder="Phone Number"
        maxLength={10}
        onKeyPress={(e) => {
          if (
            !/[0-9]/.test(e.key) &&
            !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)
          ) {
            e.preventDefault();
          }
        }}
        inputMode="numeric"
      />
    ),
  },
  {
    id: 6,
    label: "Email",
    name: "email",
    required: true,
    message: "Please input your Email",
    field: <Input type="email" readOnly />,
  },
  {
    id: 7,
    label: "Company Name",
    name: "companyName",
    required: true,
    message: "Please input your Company Name",
    field: <Input type="text" />,
  },
  {
    id: 8,
    label: "Team Name",
    name: "teamName",
    required: false,
    message: "Please input your Team Name",
    field: <Input type="text" />,
  },
  {
    id: 9,
    label: "OTP",
    name: "otp",
    field: <Input disabled />,
  },
  {
    id: 10,
    label: "Website",
    name: "website",
    required: false,
    field: <Input type="text" />,
  },
];
const EditBusinessFormItems = [
  {
    id: 1,
    label: "First Name",
    required: true,
    name: "firstName",
    message: "Please input your first Name",
    field: <Input type="text" />,
  },
  {
    id: 2,
    label: "Last Name",
    name: "lastName",
    required: false,
    message: "Please input your  last Name",
    field: <Input type="text" />,
  },

  {
    id: 3,
    label: "Phone Number",
    name: "phoneNumber",
    required: true,
    message: "Please input your phone Number",
    field: <Input type="tel" />,
  },
  {
    id: 4,
    label: "Email",
    name: "email",
    required: true,
    message: "Please input your Email",
    field: <Input type="email" />,
  },
  {
    id: 5,
    label: "Application Link",
    name: "applicationLink",
    required: true,
    message: "Please input your application link",
    field: <Input type="text" />,
  },
  {
    id: 6,
    label: "Company Name",
    name: "companyName",
    required: true,
    message: "Please input your Company Name",
    field: <Input type="text" />,
  },
  {
    id: 7,
    label: "Website",
    name: "website",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 8,
    label: "Facebook",
    name: "facebook",
    required: false,
    message: "Facebook link is required",
    field: <Input type="text" />,
  },
  {
    id: 9,
    label: "Instagram",
    name: "instagram",
    required: false,
    message: "Instagram link is required",
    field: <Input type="text" />,
  },
  {
    id: 10,
    label: "TikTok",
    name: "tiktok",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 11,
    label: "X",
    name: "x",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 12,
    label: "Youtube",
    name: "youtube",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 13,
    label: "LinkedIn",
    name: "linkedin",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 14,
    label: "SnapChat",
    name: "snapchat",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 15,
    label: "Pinterest",
    name: "pintrest",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 16,
    label: "WhatsApp",
    name: "whatsapp",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 17,
    label: "Behance",
    name: "behance",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 18,
    label: "Venmo",
    name: "venmo",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 19,
    label: "CashApp",
    name: "cashApp",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 20,
    label: "Zelle",
    name: "zelle",
    required: false,
    field: <Input type="text" />,
  },
  {
    id: 21,
    label: "Paypal",
    name: "paypal",
    required: false,
    field: <Input type="text" />,
  },
];
const interviewerFormItems = [
  {
    id: 1,
    label: "First Name",
    required: true,
    name: "firstName",
    message: "Please input your first Name",
    field: <Input />,
  },
  {
    id: 2,
    label: "Last Name",
    name: "lastName",
    required: false,
    message: "Please input your first last Name",
    field: <Input />,
  },
  {
    id: 3,
    label: "User Name",
    name: "username",
    required: true,
    message: "Please input your user Name",
    field: <Input />,
  },
  {
    id: 5,
    label: "Phone Number",
    name: "phone",
    required: true,
    message: "Please input your phone Number",
    field: (
      <Input
        type="tel"
        placeholder="Phone Number"
        maxLength={10}
        onKeyPress={(e) => {
          if (
            !/[0-9]/.test(e.key) &&
            !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)
          ) {
            e.preventDefault();
          }
        }}
        inputMode="numeric"
      />
    ),
  },
  {
    id: 5,
    label: "Email",
    name: "email",
    required: true,
    message: "Please input your Email",
    field: <Input type="email" />,
  },
  {
    id: 6,
    label: "Password",
    name: "password",
    required: true,
    message: "Please input your password",
    field: <Input />,
  },
  {
    id: 7,
    label: "Company Name",
    name: "companyName",
    required: true,
    message: "Please input your Company Name",
    field: <Input />,
  },
  {
    id: 8,
    label: "DBA Name",
    name: "dbaName",
    required: false,
    // message: "Please input your DBA Name",
    field: <Input />,
  },

  {
    id: 9,
    label: "License Number",
    name: "licenseNumber",
    required: true,
    message: "Please input your license number",
    field: (
      <Input
        maxLength={6}
        pattern="\d*"
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
      />
    ),
  },
  {
    id: 10,
    label: "State",
    name: "state",
    required: false,
    message: "Please input your state",
    field: (
      <Select
        showSearch
        allowClear
        options={statesArray?.map((el) => ({
          label: el?.stateName,
          value: el?.stateName,
        }))}
        dropdownClassName="custom-select-dropdown"
      />
    ),
  },
  {
    id: 11,
    label: "Specialty",
    name: "specialty",
    required: true,
    message: "Please select your specialty",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={ConstructionData}
      />
    ),
  },
  {
    id: 12,
    label: "Primary Language",
    name: "primary",
    required: true,
    message: "Please select your primary",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={primaryLanguage}
      />
    ),
  },
];
const interviewerEditFormItemsTier3 = [
  {
    id: 1,
    label: "First Name",
    required: true,
    name: "firstName",
    message: "Please input your first Name",
    field: <Input />,
  },
  {
    id: 2,
    label: "Last Name",
    name: "lastName",
    required: false,
    message: "Please input your first last Name",
    field: <Input />,
  },
  {
    id: 3,
    label: "Email",
    name: "email",
    required: true,
    message: "Please input your Email",
    field: <Input type="email" readOnly />,
  },
  {
    id: 4,
    label: "Phone Number",
    name: "phone",
    required: true,
    message: "Please input your phone Number",
    field: (
      <Input
        type="tel"
        placeholder="Phone Number"
        maxLength={10}
        onKeyPress={(e) => {
          if (
            !/[0-9]/.test(e.key) &&
            !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)
          ) {
            e.preventDefault();
          }
        }}
        inputMode="numeric"
      />
    ),
  },
  {
    id: 5,
    label: "State",
    name: "state",
    field: <Input />,
  },
  {
    id: 6,
    label: "OTP",
    name: "otp",
    field: <Input disabled />,
  },
];

const priceRangeOptions = [
  "0-150k",
  "150-250k",
  "250-400k",
  "500-700k",
  "700-850k",
  "850k-1Mill",
  "1Mill",
];
const applicationTypeOptions = ["Primary", "Secondary"];
const specialityOptions = ["Barndominium", "Cottage", "Villa"];

const interviewerEditFormItems = [
  {
    id: 2,
    label: "First Name",
    required: true,
    name: "firstName",
    message: "Please input your first Name",
    field: <Input />,
  },
  {
    id: 3,
    label: "Last Name",
    name: "lastName",
    required: false,
    // message: "Please input your last Name",
    field: <Input />,
  },
  {
    id: 4,
    label: "User Name",
    name: "username",
    required: true,
    message: "Please input your user Name",
    field: <Input readOnly />,
  },
  {
    id: 5,
    label: "Phone Number",
    name: "phone",
    required: true,
    message: "Please input your phone Number",
    field: (
      <Input
        type="tel"
        placeholder="Phone Number"
        maxLength={10}
        onKeyPress={(e) => {
          if (
            !/[0-9]/.test(e.key) &&
            !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)
          ) {
            e.preventDefault();
          }
        }}
        inputMode="numeric"
      />
    ),
  },
  {
    id: 6,
    label: "Email",
    name: "email",
    required: true,
    message: "Please input your Email",
    field: <Input type="email" readOnly />,
  },
  {
    id: 7,
    label: "Company Name",
    name: "companyName",
    required: true,
    message: "Please input your Company Name",
    field: <Input />,
  },
  {
    id: 8,
    label: "DBA Name",
    name: "dbaName",
    required: false,
    message: "Please input your DBA Name",
    field: <Input />,
  },
  {
    id: 9,
    label: "License Number",
    name: "licenseNumber",
    required: true,
    message: "Please input your license number",
    field: (
      <Input
        maxLength={6}
        pattern="\d*"
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
      />
    ),
  },
  {
    id: 10,
    label: "OTP",
    name: "otp",
    field: <Input disabled />,
  },
  {
    id: 11,
    label: "Website",
    name: "website",
    required: false,
    field: <Input type="text" />,
  },

  {
    id: 11,
    label: "State",
    name: "state",
    required: false,
    message: "Please input your state",
    field: (
      <Select
        dropdownClassName="custom-select-dropdown"
        showSearch
        allowClear
        options={statesArray?.map((el) => ({
          label: el?.stateName,
          value: el?.stateName,
        }))}
      />
    ),
  },
];

const PAGE_LIMIT = 20;

export {
  priceRangeOptions,
  applicationTypeOptions,
  specialityOptions,
  globalConstants,
  usersData,
  END_POINT,
  refferFormItems,
  tierEditForm,
  primaryInput,
  RESPONSE,
  optionsArray,
  specialtyOptions,
  interviewerFormItems,
  PAGE_LIMIT,
  refferEditFormItems,
  EditBusinessFormItems,
  interviewerEditFormItems,
  arrayOfItems,
  interviewerEditFormItemsTier3,
};
