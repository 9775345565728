import React from "react";

import { Box } from "@mui/material";
import { Avatar, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";

import { images } from "../../assets/images";

function AlreadyApproved({ status, profileImage }) {
  const { t } = useTranslation();
  
  return (
    <div className="main-form">
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        {profileImage ? (
          <>
            <Avatar size={120} src={profileImage} />
          </>
        ) : (
          <>
            <Avatar size={120} icon={<UserOutlined />} />
          </>
        )}

        <Typography style={{ color: "#ffffff", margin: "20px 0" }}>
          {status ? (
            <p className="normal_text">
              {t("common.Your Application is already in")} {status}
            </p>
          ) : (
            <p> {t("common.No Application found for this user")} </p>
          )}
        </Typography>
      </div>
      <div style={{ position: "absolute", bottom: "20px" }}>
        <Box>
          <div className="logoSet">
            <Avatar
              src={images?.privont_logo}
              size={70}
              style={{ backgroundColor: "black", padding: "10px" }}
            />
            <Typography className="heading_text">PRIVONT</Typography>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default AlreadyApproved;
